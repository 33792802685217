import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/seo';

import Layout from '../components/layout';
import ContentWrapper from '../components/contentWrapper';

import stylesheet from './posts.module.css';

const Posts = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <Layout>
      <SEO title="Posts" />
      <ContentWrapper>
        
        {posts
          .filter((post) => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div className={stylesheet.posts}>
                <h2 className={stylesheet.postPageHeader}>Recent Posts</h2>
                <Link className={stylesheet.postPreview} key={post.id} to={post.frontmatter.path}>
                  <div className={stylesheet.postPreviewImage}>
                    <img src={post.frontmatter.thumbnail} alt="" />
                  </div>
                  <div className={stylesheet.postPreviewText}>
                    <h4 className={stylesheet.postPreviewKicker}>{post.frontmatter.kicker}</h4>
                    <h3 className={stylesheet.postPreviewTitle}>{post.frontmatter.title}</h3>
                    <p>{post.excerpt}</p>
                    <time datetime="" className={stylesheet.postDate}>{post.frontmatter.date}</time>
                  </div>
                </Link>
              </div>
            );
          })}
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 100)
          id
          frontmatter {
            title
            date(formatString: "DD MMMM  YYYY")
            path
            thumbnail
            kicker
          }
        }
      }
    }
  }
`;

export default Posts;
